const gatedButtonWrap = document.getElementById('gated-button__wrap');
if (gatedButtonWrap) {
const gatedButton = gatedButtonWrap.querySelector('.btn');
const gatedForm = document.querySelector('.contact-24-form');
const gatedSubmit = gatedForm.querySelector('button[type="submit"]');
let gatedUrl = null;
if (gatedButtonWrap) {
    console.log('Gated button found 2');
    gatedUrl = atob(gatedButtonWrap.dataset.asset);
    console.log(gatedUrl);
    const arrowSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16" fill="currentColor">
			<path d="M7.99996 16L6.58994 14.59L12.17 8.99993H-0.00012207V6.99991H12.17L6.58994 1.40985L7.99996 -0.000162125L16 7.99992L7.99996 16Z" fill="currentColor"/>
		</svg>`;
    gatedSubmit.innerHTML = `${gatedSubmit.innerHTML} ${arrowSvg}`;
} else {
    console.log('Gated button not found');
}

function unlockGatedContent() {
    console.log('Unlocking gated content');
    gatedForm.classList.add('unlocked');
    gatedButton.href = gatedUrl;
    gatedButtonWrap.style.display = 'flex';
}

const checkIfSubmitSuccess = function(e, retryDuration = 0) {
    let success = document.querySelector('.freeform-form-success');
    console.log('Success:', success, 'dur', retryDuration);
    let newDuration = retryDuration + 100;
    console.log('New duration:', newDuration);

    if (!success) {
        if (newDuration < 5000) {
            setTimeout(() => {
                checkIfSubmitSuccess(e, newDuration);
            }, 100);
        } else {
            console.log('Failed to find success message');
            return false;
        }
    } else {
        unlockGatedContent();
    }

    

    return true;
}

gatedSubmit.addEventListener('click', checkIfSubmitSuccess);

}